import { forwardRef } from "react";
import Confetti from "react-confetti";
import {
  useGetWindowSize,
  useFractBars,
  useGetScrollDisable,
} from "../../../hooks";
import CurrentLevel from "../../CurrentLevel/CurrentLevel";
import DrawFractionsBox from "../DrawFractionsBox/DrawFractionsBox";
import ConfettiEmo from "../../ConfettiEmo/ConfettiEmo";
import FractionBarScore from "../FractionBarScore/FractionBarScore";
import MessageModal from "../../MessageModal/MessageModal";
import SignUpAndVideoLinks from "../../SignUpAndVideoLinks/SignUpAndVideoLinks";

const FractionsBar = forwardRef(
  (
    {
      score,
      setScore,
      solved,
      setSolved,
      challengeLevel,
      setChallengeLevel,
      setBlockFail,
      setCoords,
      touchEleCoords,
      setTouchEleCoords,
      showTabs,
      setShowTabs,
      mute,
      setMute,
      pullDownRef,
      setShowSignin,
      authData,
      authChecked,
      setLogoutConfirm,
      saveScores,
    },
    ref
  ) => {
    const [maxWidth, maxHeight, width, height, zoomLevel] = useGetWindowSize();

    const [
      handleNext,
      dragged,
      dropped,
      boxWidth,
      addBlock,
      removeBlock,
      slide,
      dropIndex,
      setDropIndex,
      slideRef,
      currentBlockRow,
      tableRowKeyEvent,
      fractions,
      slideComplete,
      blockPickUp,
      fractionBlockTouchMove,
      fractioBlockTouchEnd,
      tableBlockDrag,
      showSolution,
      displayLock,
      setDisplayLock,
      showConfettiEmo,
      setShowConfettiEmo,
      scorePct,
      blockHeight,
      openModal,
      setOpenModal,
      modalMessage,
      rowComplete,
      setRowComplete,
      challengeSolved,
      boxWidth2,
      levelRef,
      scrollDisable,
    ] = useFractBars({
      maxWidth,
      maxHeight,
      height,
      width,
      zoomLevel,
      setBlockFail,
      setCoords,
      setTouchEleCoords,
      score,
      setScore,
      challengeLevel,
      solved,
      setSolved,
      setShowTabs,
      ref,
      pullDownRef,
      saveScores,
    });
    useGetScrollDisable({ scrollDisable });

    return (
      <div className="absolute top-auto right-0 left-0">
        {(showTabs || showSolution) && (
          <CurrentLevel
            challengeLevel={challengeLevel}
            setChallengeLevel={setChallengeLevel}
            handleNext={handleNext}
            disableNext={false}
            linesDrawn={true}
            challengeSolved={challengeSolved}
            hideLevels={false}
            setBlockFail={setBlockFail}
            showTabs={showTabs}
            levels={[0, 1]}
          />
        )}
        <div
          className={`flex flex-col place-content-center ${
            showTabs ? "mt-0" : "mt-10"
          }`}
        >
          <SignUpAndVideoLinks
            setShowSignin={setShowSignin}
            authData={authData}
            authChecked={authChecked}
            setLogoutConfirm={setLogoutConfirm}
          />
          {showSolution && (
            <FractionBarScore
              showSolution={showSolution}
              score={score}
              setScore={setScore}
              solved={solved}
              setSolved={setSolved}
              scorePct={scorePct}
            />
          )}
          <DrawFractionsBox
            boxWidth={boxWidth}
            dragged={dragged}
            dropped={dropped}
            addBlock={addBlock}
            removeBlock={removeBlock}
            slide={slide}
            dropIndex={dropIndex}
            setDropIndex={setDropIndex}
            ref={ref}
            setBlockFail={setBlockFail}
            tableRowKeyEvent={tableRowKeyEvent}
            fractions={fractions}
            currentBlockRow={currentBlockRow}
            slideComplete={slideComplete}
            blockPickUp={blockPickUp}
            touchEleCoords={touchEleCoords}
            setTouchEleCoords={setTouchEleCoords}
            fractioBlockTouchEnd={fractioBlockTouchEnd}
            fractionBlockTouchMove={fractionBlockTouchMove}
            tableBlockDrag={tableBlockDrag}
            showSolution={showSolution}
            displayLock={displayLock}
            setDisplayLock={setDisplayLock}
            mute={mute}
            setMute={setMute}
            blockHeight={blockHeight}
            rowComplete={rowComplete}
            setRowComplete={setRowComplete}
            boxWidth2={boxWidth2}
            challengeLevel={challengeLevel}
            levelRef={levelRef}
          />
          <audio id="slideRef" autoFocus muted={mute} ref={slideRef}>
            <source src="/slide.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <>
            {showSolution && (
              <Confetti
                numberOfPieces={200}
                recycle={false}
                width={width}
                height={height}
              />
            )}
            {showConfettiEmo && (
              <ConfettiEmo
                showConfettiEmo={showConfettiEmo}
                setShowConfettiEmo={setShowConfettiEmo}
              />
            )}
          </>
        </div>

        <MessageModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          message={modalMessage}
        />
      </div>
    );
  }
);

export default FractionsBar;
