import Block from "../Block/Block";

const FractionBlocks = ({
  dragged,
  boxWidth,
  setBlockFail,
  fractions,
  currentBlockRow,
  blockPickUp,
  touchEleCoords,
  fractionBlockTouchMove,
  fractioBlockTouchEnd,
  showSolution,
  blockHeight,
  rowComplete,
  setRowComplete,
}) => (
  <div
    onDrop={(event) => {
      event?.preventDefault();
      setBlockFail(true);
    }}
    tabIndex={0}
    onFocus={() => setRowComplete(false)}
    className="self-start flex flex-wrap place-content-center"
  >
    {[...fractions].reverse().map(({ f, blockAttr }) => {
      return (
        f !== 1 && (
          <Block
            key={f}
            id={`block${f}`}
            boxWidth={boxWidth}
            blockHeight={blockHeight}
            f={f}
            dragStart={(e) => blockPickUp({ f, blockAttr })}
            focussed={(event) => {
              event.target.style.backgroundColor = "yellow";
              if (rowComplete || showSolution)
                event.target.style.backgroundColor = "";
            }}
            blurred={(event) => (event.target.style.backgroundColor = "")}
            keyDown={(event) =>
              event.key === "Enter" && blockPickUp({ f, blockAttr })
            }
            touchStart={(event) => blockPickUp({ event, f, blockAttr })}
            touchMove={(event) =>
              fractionBlockTouchMove({ event, f, boxWidth })
            }
            touchEnd={(event) =>
              fractioBlockTouchEnd({ event, touchEleCoords })
            }
            dragged={dragged}
            notDraggable={showSolution ? true : false}
            tabIndex={0}
            touchEleCoords={touchEleCoords}
            cn={`cursor-copy text-sm max-[1024px]:text-[12px] m-1 text-black font-black border-double border-8 max-[1024px]:border-solid max-[1024px]:border border-black ${
              f === dragged.f && !currentBlockRow
                ? `bg-white shadow-2xl shadow-black`
                : `${blockAttr}`
            } ${showSolution && `pointer-events-none`}`}
          />
        )
      );
    })}
  </div>
);
export default FractionBlocks;
