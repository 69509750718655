import { useGetGcfEquation, useGetWindowSize } from "../../../hooks";
import CurrentLevel from "../../CurrentLevel/CurrentLevel";
import "../style.css";
import GCFEquation from "../GCFEquation/GCFEquation";
import FactorTrees from "../FactorTrees/FactorTrees";
import PickItems from "../PickItems/PickItems";
import SignUpAndVideoLinks from "../../SignUpAndVideoLinks/SignUpAndVideoLinks";

const GreatestCommonFactor = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  setChallengeLevel,
  showTabs,
  setShowSignin,
  authData,
  authChecked,
  setLogoutConfirm,
  saveScores,
}) => {
  const [, , width, height] = useGetWindowSize();

  const [
    handleNext,
    firstNumber,
    firstNumberTree,
    secondNumber,
    secondNumberTree,
    checkFactorInput,
    errorModal,
    setErrorModal,
    message,
    checkFactor,
    firstSvgDim,
    secondSvgDim,
    inputChange,
    firstTreeComplete,
    secondTreeComplete,
    finalFactors,
    checkGcf,
    gcf,
    showSolution,
    solution,
    showConfettiEmo,
    setShowConfettiEmo,
    scorePct,
    checkSolution,
    partyItems,
    setPartyItems,
  ] = useGetGcfEquation({
    width,
    height,
    challengeLevel,
    score,
    setScore,
    solved,
    setSolved,
    saveScores,
  });

  return (
    <div className="absolute top-auto right-0 left-0">
      <CurrentLevel
        challengeLevel={challengeLevel}
        setChallengeLevel={setChallengeLevel}
        handleNext={handleNext}
        disableNext={false}
        linesDrawn={true}
        challengeSolved={false}
        showTabs={showTabs}
      />
      <div className="flex flex-col place-content-center">
        <SignUpAndVideoLinks
          setShowSignin={setShowSignin}
          authData={authData}
          authChecked={authChecked}
          setLogoutConfirm={setLogoutConfirm}
        />
        {partyItems.length < 2 ? (
          <PickItems partyItems={partyItems} setPartyItems={setPartyItems} />
        ) : (
          <>
            <GCFEquation
              firstNumber={firstNumber}
              secondNumber={secondNumber}
              showSolution={showSolution}
              finalFactors={finalFactors}
              firstTreeComplete={firstTreeComplete}
              secondTreeComplete={secondTreeComplete}
              checkGcf={checkGcf}
              gcf={gcf}
              solution={solution}
              score={score}
              solved={solved}
              scorePct={scorePct}
              checkSolution={checkSolution}
              partyItems={partyItems}
            />
            {showSolution && (
              <PickItems
                partyItems={partyItems}
                setPartyItems={setPartyItems}
                showSolution={showSolution}
                solution={solution}
                firstNumber={firstNumber}
                secondNumber={secondNumber}
              />
            )}
            <FactorTrees
              firstNumber={firstNumber}
              secondNumber={secondNumber}
              showSolution={showSolution}
              firstNumberTree={firstNumberTree}
              firstSvgDim={firstSvgDim}
              secondNumberTree={secondNumberTree}
              secondSvgDim={secondSvgDim}
              checkFactorInput={checkFactorInput}
              inputChange={inputChange}
              checkFactor={checkFactor}
              firstTreeComplete={firstTreeComplete}
              errorModal={errorModal}
              setErrorModal={setErrorModal}
              message={message}
              showConfettiEmo={showConfettiEmo}
              width={width}
              height={height}
              setShowConfettiEmo={setShowConfettiEmo}
              partyItems={partyItems}
              setPartyItems={setPartyItems}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default GreatestCommonFactor;
