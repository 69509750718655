import ScoreCard from "../../ScoreCard/ScoreCard";
import { useState, forwardRef } from "react";

const Equation = forwardRef(
  (
    {
      equation,
      currentGroupStatus,
      equationVariant,
      equationVariant2,
      showSolution,
      result,
      xVal,
      setXVal,
      disableSubmit,
      checkResult,
      scorePct,
      solved,
      score,
      challengeSolved,
    },
    ref
  ) => {
    const [scoreChange, setScoreChange] = useState(false);

    return (
      <div
        className={`max-[1024px]:text-xs flex flex-col font-bold text-black text-lg p-2 ${
          equation?.problem
            ? "border-2 border-black bg-lime-100 place-content-start"
            : "text-blue-900 place-content-center"
        }`}
      >
        <p className="self-center text-center text-purple-600">
          {challengeSolved
            ? "You did it! You turned the polygon purple, Now onto next adventure..."
            : "Turn the polygon purple by selecting the non-purple lines and solving the corresponding equation"}
        </p>
        <div className="flex flex-col place-content-center">
          <div className="flex flex-wrap place-content-evenly">
            {!currentGroupStatus.status && (
              <div className="self-center text-center">
                <div>
                  {equation?.problem}
                  {equationVariant?.problem}
                  {equationVariant2?.problem}
                  {equation?.problem && (
                    <form className="flex flex-row place-content-center">
                      <input
                        type="number"
                        ref={ref}
                        className={`${
                          showSolution &&
                          (result === "Incorrect"
                            ? "bg-red-200"
                            : "bg-green-200")
                        } text-base text-center self-center h-12 w-24 pl-3 pr-2 mt-2 rounded-lg z-0 focus:shadow focus:outline-none`}
                        placeholder="x?"
                        value={xVal}
                        onChange={(event) => setXVal(event.target.value)}
                        disabled={showSolution}
                      />
                      <div className="self-center mt-2 ml-1">
                        <button
                          type="submit"
                          disabled={disableSubmit}
                          onClick={(event) => {
                            setScoreChange(true);
                            checkResult(event);
                          }}
                          className={`h-fit w-fit border-2 text-sm outline-none p-1 ${
                            disableSubmit
                              ? "bg-gray-200 text-gray-100"
                              : "bg-blue-200 focus:bg-blue-400 hover:bg-blue-400"
                          }`}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            )}
            <ScoreCard
              type="polygon"
              showSolution={showSolution}
              result={result}
              equation={equation}
              equationVariant={equationVariant}
              equationVariant2={equationVariant2}
              currentGroupStatus={currentGroupStatus}
              scorePct={scorePct}
              score={score}
              solved={solved}
              setScoreChange={setScoreChange}
              scoreChange={scoreChange}
            />
          </div>
        </div>
        {equation?.problem && (
          <div className="text-orange-900 self-center max-[1024px]:text-sm text-lg font-bold text-center">
            Note: if numbers are not clear hover over the number for secondary
            bigger display
          </div>
        )}
      </div>
    );
  }
);

export default Equation;
