import { useState, forwardRef } from "react";
import ScoreCard from "../../ScoreCard/ScoreCard";

const AnglesEquation = forwardRef(
  (
    {
      equation,
      showSolution,
      result,
      aVal,
      setAVal,
      checkAVal,
      disableSubmit,
      showText,
      scorePct,
      solved,
      score,
      disableNext,
    },
    ref
  ) => {
    const [scoreChange, setScoreChange] = useState(false);
    return (
      <div className="flex flex-row place-content-center p-5">
        <div className="flex flex-wrap place-content-evenly text-blue-900 self-center border-2 border-black font-bold text-black text-lg p-2 bg-lime-100 text-center w-full max-[1024px]:text-xs">
          <div className="flex flex-col place-content-center">
            <div className="self-center">
              Find the value of x that creates the missing angle measure.
            </div>
            <div
              className={`self-center ${
                disableNext
                  ? "opacity-5"
                  : "transform transition duration-1000 opacity-100"
              }`}
            >
              {equation.problem}
            </div>
            <form
              className={`${
                disableNext
                  ? "opacity-5"
                  : "transform transition duration-1000 opacity-100"
              } flex flex-wrap place-content-center p-2`}
            >
              <input
                ref={ref}
                type="number"
                className={`${
                  showSolution &&
                  (result === "Incorrect" ? "bg-red-200" : "bg-green-200")
                } ${
                  !showText && "bg-gray-200 text-gray-200"
                } text-base text-center self-center h-12 w-32 pl-3 pr-2 rounded-lg z-0 focus:shadow focus:outline-none focus:bg-cyan-200 hover:bg-cyan-200`}
                placeholder="x?"
                value={aVal}
                onChange={(event) => setAVal(event.target.value)}
                disabled={showSolution || !showText}
              />
              <div className="self-center p-1">
                <button
                  type="submit"
                  disabled={disableSubmit}
                  onClick={(event) => {
                    event.preventDefault();
                    setScoreChange(true);
                    checkAVal();
                  }}
                  className={`h-fit w-fit border-2 text-sm  p-1
            ${
              disableSubmit
                ? "bg-gray-200 text-gray-100"
                : "bg-blue-200 hover:bg-blue-400"
            }
            `}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <ScoreCard
            type="angle"
            showSolution={showSolution}
            result={result}
            equation={equation}
            scorePct={scorePct}
            score={score}
            solved={solved}
            setScoreChange={setScoreChange}
            scoreChange={scoreChange}
          />
        </div>
      </div>
    );
  }
);
export default AnglesEquation;
