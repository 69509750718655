import { useRef, useEffect } from "react";
import Fractions from "../../Fractions/Fractions";

const LineNumberRadioInput = ({
  showDividendLines,
  showDivisorLines,
  equation,
  setQVal,
  showSolution,
}) => {
  const radioInputRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    let timerId;
    if (showDividendLines && showDivisorLines) {
      radioInputRef?.current?.focus();

      timerId = setTimeout(() => {
        inputRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, [1500]);
    }
    return () => clearTimeout(timerId);
  }, [showDividendLines, showDivisorLines]);

  return (
    <div
      ref={inputRef}
      className="w-full border border-dashed border-black self-center"
    >
      <fieldset className="flex flex-row place-content-evenly">
        <legend className="self-center">Select an answer</legend>

        {equation?.options.map((a, index) => (
          <div className="flex flex-row place-content-center" key={a}>
            <input
              className={`text-base self-center h-4 w-4 max-[1024px]:h-3 max-[1024px]:w-3 cursor-pointer rounded-full appearance-none border-2 border-blue-900
              checked:border-[6px] checked:max-[1024px]:border-[5px] checked:border-black
              focus:border-[6px] focus:max-[1024px]:border-[5px] focus:border-slate-400
              hover:border-[6px] hover:max-[1024px]:border-[5px] hover:border-slate-400
              disabled:pointer-events-none`}
              ref={radioInputRef}
              type="radio"
              name="answer"
              value={`${a}`}
              onClick={(event) => setQVal(event.target.value)}
              disabled={showSolution}
            />

            <label htmlFor={`${a}`} className={`self-center p-1`}>
              <div className="flex flex-row place-content-center">
                <div className="self-center text-sm max-[1024px]:text-xs max-[300px]:text-[12px]">
                  {equation?.splitOptions[index].w}
                </div>
                <Fractions
                  small={true}
                  numerator={equation?.splitOptions[index].rn}
                  denominator={equation?.splitOptions[index].rd}
                />
              </div>
            </label>
          </div>
        ))}
      </fieldset>
    </div>
  );
};
export default LineNumberRadioInput;
