const EquationHeader = ({ firstNumber, secondNumber, partyItems }) => (
  <div className="self-center">
    Determine the{" "}
    <u>
      <b>most number of party bags</b>
    </u>{" "}
    you can make with
    <i className="text-blue-600"> {firstNumber} </i>{" "}
    {partyItems[0].label.toLowerCase()} and
    <i className="text-red-600"> {secondNumber} </i>
    {partyItems[1].label.toLowerCase()} so that each bag has the same number of{" "}
    {partyItems[0].label.toLowerCase()} and {partyItems[1].label.toLowerCase()}.{" "}
    <p className="text-sm max-[1024px]:text-xs">
      (For example: You have 4 {partyItems[0].label.toLowerCase()} and 6{" "}
      {partyItems[1].label.toLowerCase()}, you can make total of 2 bags, each
      bag can have 2 {partyItems[0].label.toLowerCase()} and 3{" "}
      {partyItems[1].label.toLowerCase()})
    </p>
  </div>
);

export default EquationHeader;
