import { forwardRef } from "react";
import { BiExpandVertical, BiCollapseVertical } from "react-icons/bi";

const ColumnData1n2 = forwardRef(
  (
    {
      qType,
      valType,
      itemType,
      checkVal,
      index,
      columnValue,
      onChangeItem,
      status,
      showButton,
      expand,
      expandCollapse,
      rowIndex,
    },
    ref
  ) => (
    <td className="max-[1024px]:text-xs relative w-24 h-20 border-2 border-black text-center font-bold bg-slate-200">
      {qType === "?" ? (
        <form
          className={`absolute top-0 bottom-0 w-full ${
            columnValue.index === index && columnValue.val ? "p=0" : "p-2"
          } `}
          onSubmit={(event) =>
            checkVal({
              currentIndex: index,
              type: valType,
              event,
            })
          }
        >
          <input
            ref={ref}
            type="number"
            className={`text-base focus:bg-cyan-200 hover:bg-cyan-200 border border-black w-full text-center self-center ${
              columnValue.index === index && columnValue.val ? "h-12" : "h-16"
            } ${
              status === "Incorrect" &&
              columnValue.index === index &&
              columnValue.val
                ? "bg-red-200"
                : "bg-white"
            }`}
            placeholder="?"
            value={columnValue.index === index ? columnValue.val : ""}
            onChange={(event) => onChangeItem({ event, currentIndex: index })}
            disabled={qType === itemType}
          />
          {columnValue.index === index && columnValue.val && (
            <button
              onClick={(event) =>
                checkVal({
                  currentIndex: index,
                  type: valType,
                  event,
                })
              }
              className="pl-1 pr-1 text-white border rounded-full bg-green-900 outline-none hover:bg-green-600 focus:bg-green-600"
              disabled={columnValue.index === index && !columnValue.val}
            >
              check
            </button>
          )}
        </form>
      ) : (
        <div className="absolute top-0 bottom-0 w-full">
          <div className="flex flex-wrap place-content-center h-20">
            <div className="w-1/2 self-center text-center">{qType}</div>
            {showButton && (
              <button
                className={`${
                  expand
                    ? status !== "solved"
                      ? "text-slate-400"
                      : "text-violet-900 focus:text-violet-700 focus:bg-slate-400 hover:text-violet-700 hover:bg-slate-400"
                    : status !== "solved"
                    ? "text-slate-400"
                    : "text-green-900 focus:text-green-700 focus:bg-slate-400 hover:text-green-700 hover:bg-slate-400"
                } w-1/2 self-center flex place-content-end outline-none`}
                onClick={() => expandCollapse({ currentIndex: rowIndex })}
                disabled={status !== "solved"}
              >
                {expand ? (
                  <BiCollapseVertical className={`self-center text-9xl`} />
                ) : (
                  <BiExpandVertical className={`self-center text-9xl`} />
                )}
              </button>
            )}
          </div>
        </div>
      )}
    </td>
  )
);
export default ColumnData1n2;
