import Block from "../Block/Block";

const TableBlocks = ({
  rowIndex,
  tableBlockDrag,
  showSolution,
  dropped,
  dropIndex,
  removeBlock,
  touchEleCoords,
  setTouchEleCoords,
  fractionBlockTouchMove,
  fractioBlockTouchEnd,
  boxWidth,
  blockHeight,
}) =>
  !((rowIndex > 0 || showSolution) && dropped[rowIndex]?.rowLocked) &&
  dropped[rowIndex].map(({ f, blockAttr, blockLocked }, index2) => (
    <Block
      id={index2}
      key={index2}
      rowIndex={rowIndex}
      blockIndex={index2}
      dropped={dropped}
      tabIndex={dropped[rowIndex]?.rowLocked || blockLocked ? -1 : 0}
      f={f}
      dragStart={() =>
        tableBlockDrag({
          rowIndex,
          blockIndex: index2,
          f,
          blockAttr,
        })
      }
      dragEnd={() => {
        if (!dropIndex) {
          removeBlock({ rowIndex: rowIndex, blockIndex: index2 });
        }
      }}
      touchStart={(event) =>
        tableBlockDrag({
          event,
          rowIndex: rowIndex,
          blockIndex: index2,
          f,
          blockAttr,
        })
      }
      touchMove={(event) => fractionBlockTouchMove({ event, f, boxWidth })}
      touchEnd={(event) =>
        fractioBlockTouchEnd({
          event,
          touchEleCoords,
          rowIndex: rowIndex,
          blockIndex: index2,
        })
      }
      touchEleCoords={touchEleCoords}
      setTouchEleCoords={setTouchEleCoords}
      boxWidth={boxWidth}
      blockHeight={blockHeight}
      notDraggable={dropped[rowIndex]?.rowLocked || blockLocked ? true : false}
      cn={`${
        (dropped[rowIndex]?.rowLocked || blockLocked) && "pointer-events-none"
      } cursor-move self-start ${blockAttr} focus:bg-yellow-300 focus:border-green-600 text-sm max-[1024px]:text-[12px] text-black font-black max-[1024px]:border-solid max-[1024px]:border border-double border-8 border-black`}
    />
  ));
export default TableBlocks;
