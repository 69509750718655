import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Spinner from "../Loader/Spinner";
import Flight from "../Loader/Flight";
import MessageModal from "../MessageModal/MessageModal";
import SigninModal from "../SignUpAndVideoLinks/SigninModal";
import DetailsView from "../DetailsView/DetailsView";

const Popups = ({
  logoutConfirm,
  setLogoutConfirm,
  logout,
  gameChangeConfirm,
  setGameChangeConfirm,
  setTabSelect,
  tabClicked,
  games,
  showSpinner,
  showFlight,
  saveDataFail,
  setSaveDataFail,
  authProps,
  showTeacherDetails,
  setShowTeacherDetails,
  showStudentDetails,
  setShowStudentDetails,
}) => (
  <>
    {showSpinner && <Spinner />}
    {showFlight && (
      <div className="absolute top-0 w-screen h-screen z-50 flex place-content-center">
        <Flight
          times={1}
          cn={"w-full h-full self-center bg-sky-200"}
          transitionColor={"from-[#DFCFBE] via-[#537263] to-[#DFCFBE]"}
        />
      </div>
    )}
    {logoutConfirm && (
      <ConfirmModal
        setChangeConfirm={setLogoutConfirm}
        restartFn={() => {
          logout();
          setLogoutConfirm(false);
        }}
        confirmMessage={`Scores will reset for all games and will not be saved once Logged out, Confirm logout? `}
      />
    )}
    {gameChangeConfirm && (
      <ConfirmModal
        setChangeConfirm={setGameChangeConfirm}
        restartFn={() => {
          setTabSelect(tabClicked);
          setGameChangeConfirm(false);
        }}
        confirmMessage={
          <div className="flex flex-row place-content-center p-5">
            <div className="self-center text-black">Jump to game&nbsp;</div>
            <div className="self-center italic">"{games[tabClicked]}"? </div>
          </div>
        }
      />
    )}
    <MessageModal
      openModal={saveDataFail}
      closeModal={() => setSaveDataFail(false)}
      message={{
        message: (
          <div className="flex flex-wrap place-content-center">
            <div className="self-center text-center">
              Unable to save score at this time!
            </div>
          </div>
        ),
        bgColor: "bg-orange-800",
      }}
    />
    <SigninModal {...authProps} />
    <DetailsView
      showTeacherDetails={showTeacherDetails}
      setShowTeacherDetails={setShowTeacherDetails}
      showStudentDetails={showStudentDetails}
      setShowStudentDetails={setShowStudentDetails}
    />
  </>
);
export default Popups;
