import { useEffect } from "react";

const useGetScrollDisable = ({ scrollDisable }) =>
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    let supportsPassive = false;
    try {
      window.addEventListener(
        "disableScroll",
        null,
        Object.defineProperty({}, "passive", {
          get: () => (supportsPassive = true),
        })
      );
    } catch (e) {}

    const wheelOpt = supportsPassive ? { passive: false } : false;
    if (scrollDisable) {
      window.addEventListener("touchmove", preventDefault, wheelOpt);
    } else {
      window.removeEventListener("touchmove", preventDefault, wheelOpt);
    }

    return () =>
      window.removeEventListener("touchmove", preventDefault, wheelOpt);
  }, [scrollDisable]);

export default useGetScrollDisable;
