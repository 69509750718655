import { useState, useEffect } from "react";

const useGetWindowSize = () => {
  const [zoomLevel, setZoomLevel] = useState(
    window.outerWidth / window.innerWidth
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [maxWidth, setMaxWidth] = useState(
    zoomLevel !== 1 ? window.outerWidth * 0.8 : window.innerWidth * 0.8
  );
  const [maxHeight, setMaxHeight] = useState(
    window.innerWidth < 1024 ? 300 : window.innerHeight * 0.6
  );
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const getSize = () => {
      setTimeout(() => {
        const zLevel = window.outerWidth / window.innerWidth;
        setZoomLevel(zLevel);
        setWidth(zLevel !== 1 ? window.outerWidth : window.innerWidth);
        setHeight(zLevel !== 1 ? window.outerHeight : window.innerHeight);
        setMaxWidth(
          zLevel !== 1 ? window.outerWidth * 0.8 : window.innerWidth * 0.8
        );
        setMaxHeight(window.innerWidth < 1024 ? 300 : window.innerHeight * 0.6);
        setIsMobile(
          /iphone|ipad|ipod|android|windows phone/g.test(
            navigator.userAgent.toLowerCase()
          )
        );
      }, [1000]);
    };

    setIsMobile(
      /iphone|ipad|ipod|android|windows phone/g.test(
        navigator.userAgent.toLowerCase()
      )
    );
    window.addEventListener("resize", getSize);
    window.addEventListener("gestureend", getSize);

    return () => {
      window.removeEventListener("resize", getSize);
      window.removeEventListener("gestureend", getSize);
    };
  }, []);

  return [maxWidth, maxHeight, width, height, zoomLevel, isMobile];
};

export default useGetWindowSize;
