import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import findindex from "lodash.findindex";
import { HiEmojiSad } from "react-icons/hi";
import { BsFillEmojiAngryFill } from "react-icons/bs";
import { GiTrophyCup } from "react-icons/gi";
import Fractions from "../components/Fractions/Fractions";
import Fraction from "fraction.js";

const useFractBars = ({
  maxWidth,
  maxHeight,
  width,
  height,
  zoomLevel,
  setBlockFail,
  blockFail,
  setCoords,
  setTouchEleCoords,
  score,
  setScore,
  challengeLevel,
  solved,
  setSolved,
  setShowTabs,
  ref,
  pullDownRef,
  saveScores,
}) => {
  const [dragged, setDragged] = useState("");
  const [dropped, setDropped] = useState([[], [], [], [], []]);
  const [droppedTemp, setDroppedTemp] = useState([[], [], [], [], []]);
  const [boxWidth, setBoxWidth] = useState("");
  const [boxWidth2, setBoxWidth2] = useState("");
  const [blockHeight, setBlockHeight] = useState("");
  const [slide, setSlide] = useState(false);
  const [dropIndex, setDropIndex] = useState(null);
  const slideRef = useRef(null);
  const [firstRow, setFirstRow] = useState([[], [], [], [], []]);
  const [currentBlockRow, setCurrentBlockRow] = useState("");
  const [rowNum, setRowNum] = useState(null);
  const [showSolution, setShowSolution] = useState(false);
  const [displayLock, setDisplayLock] = useState(false);
  const [showConfettiEmo, setShowConfettiEmo] = useState(false);
  const [screenChange, setScreenChange] = useState(false);
  const [scorePct, setScorePct] = useState({
    previousScore: "0%",
    currentScore: "0%",
  });
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [rowComplete, setRowComplete] = useState(false);
  const [challengeSolved, setChallengeSolved] = useState(false);
  const [callSaveScores, setCallSaveScores] = useState(false);
  const [scrollDisable, setScrollDisable] = useState(false);

  const levelRef = useRef(null);
  let fracts = useRef(null);

  const fractions = useMemo(
    () => [
      { f: 1, blockAttr: "bg-cyan-300", colorCode: "#67E8F9" },
      { f: 2, blockAttr: "bg-[#dfcfbe]", colorCode: "#dfcfbe" },
      { f: 3, blockAttr: "bg-[#daebe8]", colorCode: "#daebe8" },
      { f: 4, blockAttr: "bg-[#f9ccac]", colorCode: "#f9ccac" },
      { f: 5, blockAttr: "bg-[#b7d7e8]", colorCode: "#b7d7e8" },
      { f: 6, blockAttr: "bg-[#a79e84]", colorCode: "#a79e84" },
      { f: 8, blockAttr: "bg-[#87bdd8]", colorCode: "#87bdd8" },
      { f: 10, blockAttr: "bg-[#f9d5e5]", colorCode: "#f9d5e5" },
      { f: 12, blockAttr: "bg-[#c8c3cc]", colorCode: "#c8c3cc" },
    ],
    []
  );

  const getUnique = useCallback(
    (fractArr) => {
      const exclude = findindex(fractions, (fr) => fr.f === fractArr[0].f);
      let cols = [];
      for (let i = 0; i < 3; i++) {
        while (!cols[i]) {
          let r = Math.floor(Math.random() * (9 - 1) + 1);
          if (!cols.includes(r) && r !== exclude) {
            cols.push(r);
          }
        }
      }
      return cols;
    },
    [fractions]
  );

  const resetData = useCallback(() => {
    setScrollDisable(false);
    setDropped([[], [], [], [], []]);
    setDroppedTemp([[], [], [], [], []]);
    setDragged("");
    setDropIndex(null);
    setSlide(false);
    setFirstRow([[], [], [], [], []]);
    setRowNum(null);
    setDisplayLock(false);
    setShowSolution(false);
    setShowTabs(false);
    setChallengeSolved(false);
    setRowComplete(false);
    fracts.current = [];
    setTimeout(() => {
      pullDownRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      pullDownRef?.current?.focus();
    }, [1000]);
  }, [setShowTabs, pullDownRef]);

  const handleNext = useCallback(() => {
    resetData();
    const adders = fractions.filter(({ f }) => f > 1);
    if (challengeLevel === 0) {
      const index = Math.floor(Math.random() * adders.length);
      const { f } = adders[index];
      let whole = parseFloat((1 / f).toPrecision(10));
      let fractArr = [adders[index]];
      while (whole < 1) {
        const j = Math.floor(Math.random() * adders.length);
        whole = parseFloat((whole + 1 / adders[j].f).toPrecision(10));
        fractArr = [adders[j], ...fractArr];

        if (whole > 1) {
          fractArr = [];
          whole = 0;
        }
        if (whole === 1) {
          let dups = findDuplicates([...fractArr]);
          if (dups.length === 1) {
            fractArr = [];
            whole = 0;
          } else {
            let cols = getUnique(fractArr);

            if (cols.length === 3) {
              setFirstRow([
                fractions.filter(({ f }) => f === 1),
                [...fractArr],
                [fractions[cols[0]]],
                [fractions[cols[1]]],
                [fractions[cols[2]]],
              ]);
              break;
            }
          }
        }
      }
    } else if (challengeLevel === 1) {
      let f1, f2;
      const getFracts = () => {
        f1 = Math.floor(Math.random() * adders.length);
        f2 = Math.floor(Math.random() * adders.length);
        while (f2 === f1) {
          f2 = Math.floor(Math.random() * adders.length);
        }
        const lcm = getLcm({
          p1: adders[f1].f,
          p2: adders[f2].f,
          adders,
          f1,
          f2,
        });
        return [lcm, { f1: adders[f1], f2: adders[f2] }];
      };

      while (findindex(fractions, (fr) => fr.f === fracts.current[0]) === -1)
        fracts.current = getFracts();

      let fractArr = [fracts.current[1].f1, fracts.current[1].f2];

      setFirstRow([[...fractArr]]);
    }
  }, [fractions, getUnique, challengeLevel, resetData]);

  const blockPickUp = ({ event, f, blockAttr }) => {
    if (event?.type === "touchstart") setScrollDisable(true);
    let temp = [...dropped];
    resetAttributes(temp);
    setDropped([...temp]);
    setSlide(false);
    setSlide(false);
    setDragged({ f, blockAttr });
    setCurrentBlockRow("");
    setTouchEleCoords("");
  };

  const tableBlockDrag = ({ event, rowIndex, blockIndex, f, blockAttr }) => {
    if (event?.type === "touchstart") setScrollDisable(true);
    setCurrentBlockRow({ rowIndex, blockIndex });
    setDropIndex(null);
    setDragged({ f, blockAttr });
  };

  const addBlock = (index) => {
    const { rowIndex, blockIndex } = currentBlockRow;
    if (currentBlockRow) {
      removeBlock({ rowIndex, blockIndex });
    }

    setSlide(true);
    let temp = [...dropped];
    let sum = 1 / dragged.f;
    temp[index].forEach((t) => {
      sum = sum + 1 / t.f;
    });
    let total = 0;
    if (challengeLevel === 0) {
      total = 1;
    } else {
      temp[0].forEach((t) => {
        total = total + 1 / t.f;
      });
      total = parseFloat(total.toPrecision(10));
    }

    sum = parseFloat(sum.toPrecision(10));

    if (sum <= total) {
      temp[index] = [...temp[index], dragged];
      if (sum === total) {
        let dups =
          challengeLevel > 0
            ? findDuplicates2([...temp[index]])
            : findDuplicates([...temp[index]]);
        if (dups.length === 1) {
          setModalMessage({
            message: (
              <div className="flex flex-wrap place-content-center">
                <div className="self-center">
                  {challengeLevel > 0
                    ? "Answers cannot be same as first row"
                    : `All blocks in a row cannot be the same!`}
                </div>
                <HiEmojiSad className="self-center text-yellow-300 text-3xl" />
              </div>
            ),
            bgColor: "bg-orange-800",
          });
          unSLide();
          return;
        } else {
          temp[index].blockColors = fuseBlocks({
            row: temp[index],
            challengeLevel,
          });
          if (challengeLevel > 0) {
            const rs = new Fraction(total).toFraction(true).split("/");
            const rightSide = (
              <div className="max-[1024px]:text-xs flex flex-row place-content-center text-sm">
                <Fractions
                  small={false}
                  numerator={rs[0]}
                  denominator={rs[1]}
                  dividerColor={"border-black self-center"}
                />
              </div>
            );
            temp[0].blockColors = fuseBlocks({ row: temp[0], challengeLevel });
            temp[0].rightSide = rightSide;
            temp[index].rightSide = rightSide;
            setShowSolution(true);
          } else {
            temp[index].rightSide = 1;
          }

          setRowComplete(true);
          if (
            challengeLevel === 0 &&
            temp.filter(({ rowLocked }) => rowLocked).length < 3
          ) {
            setOpenModal(true);
            setModalMessage({
              message: (
                <div className="flex flex-wrap place-content-center">
                  <div className="self-center">Well Done!&nbsp;</div>
                  <GiTrophyCup className="self-center text-white text-xl" />
                </div>
              ),
              bgColor: "bg-teal-800",
            });
          }
        }
      }
      dragged.blockAttr = fractions.filter(
        ({ f }) => f === dragged.f
      )[0].blockAttr;
      dragged.blockLocked = sum === total;
      temp[index].rowLocked = sum === total;
      temp[index].duplicates = findDuplicates([...temp[index]]);
      let playPromise = slideRef?.current?.play();
      if (playPromise !== undefined) {
        playPromise.then((_) => {}).catch((error) => {});
      }
      setDroppedTemp([...temp]);
      setTimeout(() => {
        setSlide(false);
      }, [510]);
    } else {
      setModalMessage({
        message: (
          <div className="flex flex-wrap place-content-center">
            <div className="self-center">
              The total of blocks exceeds the sum, Try Again!&nbsp;
            </div>
            <BsFillEmojiAngryFill className="self-center text-yellow-300 text-3xl" />
          </div>
        ),
        bgColor: "bg-orange-800",
      });
      unSLide();
    }
    document.activeElement.blur();
  };

  const fuseBlocks = useCallback(
    ({ row, challengeLevel }) => {
      let colors = [];
      row.forEach(
        (t) =>
          (colors = [
            ...colors,
            {
              colorCode: fractions.filter(({ f }) => f === t.f)[0].colorCode,
              start: parseFloat(
                (
                  ((boxWidth * (1 / t.f)) /
                    (challengeLevel > 0 ? boxWidth2 : boxWidth)) *
                  100
                ).toPrecision(10)
              ),
            },
          ])
      );
      let total = [colors[0]];
      let i = 1;
      while (i < colors.length) {
        total.push({
          colorCode: colors[i].colorCode,
          start: colors[i].start + total[i - 1].start,
        });
        i++;
      }
      let blockColors = "linear-gradient(to right,";

      total.forEach(
        (t, i) =>
          (blockColors =
            i === 0
              ? total.length === 1
                ? `${blockColors} ${t.colorCode} 0% 100%)`
                : `${blockColors} ${t.colorCode} ${t.start}%,`
              : i === total.length - 1
              ? `${blockColors} ${t.colorCode} ${total[i - 1].start}%)`
              : `${blockColors} ${t.colorCode} ${total[i - 1].start}% ${
                  t.start
                }%,`)
      );
      return blockColors;
    },
    [fractions, boxWidth, boxWidth2]
  );

  const unSLide = () => {
    setOpenModal(true);
    setSlide(false);
    setBlockFail(true);
    setDragged("");
    let c = document.activeElement.getBoundingClientRect();
    setCoords({
      left: c.left,
      top: c.top - 130,
    });
    document.activeElement.blur();
  };

  const slideComplete = () => {
    setDropped([...droppedTemp]);
    setDragged("");
    setDroppedTemp([[], [], [], [], []]);
    setTouchEleCoords("");
  };

  const findDuplicates = (arr) => {
    let dups = {};
    let dupsArr = [];
    arr.forEach((t) => {
      dups[t.f] = (dups[t.f] || 0) + 1;
    });
    for (const [key, value] of Object.entries(dups)) {
      dupsArr.push({ f: key, count: value });
    }
    return dupsArr;
  };

  const findDuplicates2 = (arr) => {
    let dupsArr = [];
    if (dropped[0].length === arr.length) {
      dropped[0].forEach((d) => {
        for (const [, value] of Object.entries(arr)) {
          if (value.f !== d.f) {
            return;
          } else {
            dupsArr.push({ f: value.f });
          }
        }
      });
    }
    return dupsArr;
  };

  const removeBlock = ({ rowIndex, blockIndex }) => {
    let temp = [...dropped];
    temp[rowIndex].splice(blockIndex, 1);
    setDropped([...temp]);
    document.activeElement.blur();
  };

  const resetAttributes = (arr) =>
    arr.map((r) =>
      r.map(
        (c) =>
          (c.blockAttr = fractions.filter(({ f }) => f === c.f)[0].blockAttr)
      )
    );

  const tableRowKeyEvent = ({ event, index }) => {
    if (event.key === "Enter") {
      if (dragged) {
        addBlock(index);
      } else {
        const blockIndex = parseInt(document.activeElement.id);
        if (blockIndex >= 0) {
          let temp = [...dropped];
          resetAttributes(temp);
          setCurrentBlockRow({ rowIndex: index, blockIndex });
          setDropIndex(null);
          setDragged(dropped[index][blockIndex]);

          temp[index][blockIndex].blockAttr = "bg-white -translate-y-2";
          setDropped([...temp]);
        }
      }
      setDropIndex(index);
    } else if (event.key === "Backspace") {
      const blockIndex = parseInt(document.activeElement.id);
      setCurrentBlockRow({ rowIndex: index, blockIndex });
      if (blockIndex >= 0) {
        setDragged("");
        removeBlock({ rowIndex: index, blockIndex });
      }
    }
  };

  const tableRowTouchEvent = ({ rowIndex }) => {
    setDropIndex(rowIndex);
    if (dragged) {
      addBlock(rowIndex);
    }
  };

  const fractionBlockTouchMove = ({ event, f, boxWidth }) => {
    if (event?.type === "touchmove") setScrollDisable(true);
    const item = event.targetTouches.item(0);
    if (event.touches.length === event.targetTouches.length) {
      setTouchEleCoords({
        left: item.clientX - boxWidth / f / 2 + "px",
        top: item.clientY - 70 + "px",
      });
    }
  };

  const fractioBlockTouchEnd = ({
    event,
    touchEleCoords,
    rowIndex,
    blockIndex,
  }) => {
    if (event?.type === "touchend") setScrollDisable(false);
    const xy = [
      { c: 0, pos: "left" },
      { c: 0, pos: "top" },
    ];
    xy.map((a, index) => {
      if (touchEleCoords) {
        let xy = touchEleCoords[a.pos].split("");
        xy.splice(xy.length - 2);
        a.c = parseFloat(xy.join("")) + index * 30;
      }
      return a;
    });
    const x = xy[0].c,
      y = xy[1].c;

    let blockFit = false;

    [...new Array(challengeLevel > 0 ? 2 : 5)].map((a, index) => {
      const r = document.getElementById(`row${index}`).getBoundingClientRect();
      if (x >= r.left && x <= r.right && y >= r.top && y <= r.bottom) {
        index > 0 && tableRowTouchEvent({ rowIndex: index });
        blockFit = index > 0;
      }
      setTouchEleCoords("");
      return a;
    });
    if (!blockFit) {
      if (rowIndex && blockIndex && touchEleCoords)
        removeBlock({ rowIndex: rowIndex, blockIndex });
      setSlide(false);
      setBlockFail(true);
      setDragged("");
      setCoords({
        left: xy[0].c + "px",
        top: xy[1].c - 100 + "px",
      });
    }
  };

  const getLcm = ({ p1, p2, adders, f1, f2 }) => {
    while (p2) {
      let t = p2;
      p2 = p1 % p2;
      p1 = t;
    }
    const gcd = p1;
    let lcm = (adders[f1].f * adders[f2].f) / gcd;
    return lcm;
  };

  const autoFill = useCallback(() => {
    if (firstRow[rowNum][0]) {
      setDragged(firstRow[rowNum][0]);
      setDropIndex(rowNum);
      let temp = [...dropped];
      firstRow[rowNum][0].blockLocked = true;
      temp[rowNum].push(firstRow[rowNum][0]);
      temp[rowNum].rowLocked = rowNum === 0;
      temp[rowNum].rightSide = rowNum === 0 && challengeLevel === 0 ? 1 : "";

      temp[rowNum].duplicates = findDuplicates([...temp[rowNum]]);
      setDropped([...temp]);
      let fr = [...firstRow];
      fr[rowNum] = [...firstRow[rowNum]].slice(1);
      setFirstRow([...fr]);
      setDragged("");
    }
  }, [dropped, firstRow, rowNum, challengeLevel]);

  useEffect(() => {
    let num = null;
    for (const [index, el] of firstRow.entries()) {
      if (el.length > 0) {
        num = index;
        break;
      }
    }
    if (firstRow[num]?.length > 0) {
      setRowNum(num);
    } else {
      setRowNum(null);
    }
  }, [firstRow]);

  useEffect(() => {
    let intervalID;
    if (rowNum !== null) {
      intervalID = setInterval(() => {
        autoFill();
      }, 50);
    }
    return () => clearInterval(intervalID);
  }, [rowNum, autoFill]);

  useEffect(() => handleNext(), [handleNext]);

  useEffect(() => {
    if ([dropped[2], dropped[3], dropped[4]].every((d) => d.rowLocked)) {
      setDropped((dropped) => {
        dropped[1].rowLocked = true;
        dropped[1].rightSide = 1;
        dropped[1].blockColors = fuseBlocks({ row: dropped[1] });
        dropped[0].blockColors = fuseBlocks({ row: dropped[0] });
        return dropped;
      });
      setShowSolution(true);
    }
  }, [dropped, fuseBlocks]);

  useEffect(() => {
    let timerId, timerId2;
    if (showSolution) {
      setCallSaveScores(true);
      setOpenModal(false);
      timerId = setTimeout(() => setShowConfettiEmo(true), [1500]);
      timerId2 = setTimeout(() => setChallengeSolved(true), [4700]);

      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setSolved((solved) => {
        solved.overAll = solved.overAll + 1;
        solved[`level${levelRef.current + 1}`] =
          solved[`level${levelRef.current + 1}`] + 1;
        return solved;
      });
      setScore((score) => {
        score[`level${levelRef.current + 1}`] =
          score[`level${levelRef.current + 1}`] + 1;
        score.overAll = score.overAll + 1;
        return score;
      });
    }
    return () => {
      clearTimeout(timerId);
      clearTimeout(timerId2);
    };
  }, [showSolution, setSolved, setScore, ref]);

  useEffect(() => {
    setScreenChange(true);
    setBoxWidth("");
    setBoxWidth2("");
    setBlockHeight("");
  }, [height, width, zoomLevel]);

  useEffect(() => {
    if (screenChange) {
      setBoxWidth(Math.floor(width) * 0.5);
      if (fracts?.current && fracts?.current?.length > 0) {
        setBoxWidth2(
          Math.floor(width) *
            0.5 *
            (1 / fracts.current[1].f1.f + 1 / fracts.current[1].f2.f)
        );
      }
      setBlockHeight(Math.floor(height / 10));
      setScreenChange(false);
    }
  }, [challengeLevel, screenChange, height, width]);

  useEffect(() => {
    if (challengeLevel === 1 && firstRow[0].length === 2) {
      if (fracts?.current && fracts?.current?.length > 0) {
        setBoxWidth2(
          Math.floor(width) *
            0.5 *
            (1 / fracts.current[1].f1.f + 1 / fracts.current[1].f2.f)
        );
      }
    }
  }, [firstRow, challengeLevel, width]);

  useEffect(() => {
    setScorePct(({ currentScore }) => {
      return {
        previousScore: currentScore,
        currentScore: `${
          solved.overAll && Math.round((score.overAll * 100) / solved.overAll)
        }%`,
      };
    });
  }, [solved.overAll, score.overAll]);

  useEffect(() => {
    if (callSaveScores) {
      saveScores({ solved, score, type: "fractionBars" });
      setCallSaveScores(false);
    }
  }, [solved, score, saveScores, callSaveScores]);

  return [
    handleNext,
    dragged,
    dropped,
    boxWidth,
    addBlock,
    removeBlock,
    slide,
    dropIndex,
    setDropIndex,
    slideRef,
    currentBlockRow,
    tableRowKeyEvent,
    fractions,
    slideComplete,
    blockPickUp,
    fractionBlockTouchMove,
    fractioBlockTouchEnd,
    tableBlockDrag,
    showSolution,
    displayLock,
    setDisplayLock,
    showConfettiEmo,
    setShowConfettiEmo,
    scorePct,
    blockHeight,
    openModal,
    setOpenModal,
    modalMessage,
    rowComplete,
    setRowComplete,
    challengeSolved,
    boxWidth2,
    levelRef,
    scrollDisable,
  ];
};
export default useFractBars;
